<template>
  <teleport to="head">
    <title>ДОСТАВКА И ОПЛАТА в Ореховском</title>
  </teleport>
  <div class="exchange main page__view">
    <h2 class="exchange__header-text header-text">ДОСТАВКА И ОПЛАТА</h2>
    <p class="exchange__secondary-text">
      Мы подобрали максимально удобные способы транспортировки, а также
      разработали короткие маршруты по всей Москве и Московский област..
    </p>
    <h4 class="exchange__header-text header-text">
      ДОСТАВКА ПО МОСКОВСКИЙ ОБЛАСТИ И МОСКВУ
    </h4>
    <p class="exchange__secondary-text">
      Доставка тротуарной плитки осуществляется нашим транспортом в любую точку
      Москве и Московский област.
    </p>
    <h4 class="exchange__header-text header-text">
      ВАРИАНТЫ ОПЛАТЫ
    </h4>
    <p class="exchange__secondary-text">
      Наши клиенты могут выбирать оплату наличными или безналичным способом. В
      заказе могут присутствовать все необходимые фигурные элементы мощения в
      разных цветах, а также бордюр дорожный нужного типоразмера. Постоянные
      наши клиенты получают специальные скидки. Свяжитесь с менеджерами, чтобы
      уточнить детали по вашему заказу.
    </p>
  </div>
</template>

<style scoped>
.exchange__header-text,
.exchange__secondary-text {
  margin-top: 10px;
}
.exchange__secondary-text {
  font-size: 1.1em;
}
</style>
